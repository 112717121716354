/* istanbul ignore file */

import dynamic from "next/dynamic";

// Templates
const Page = dynamic(() => import("./magnolia/templates/Page"));
const EventsPage = dynamic(() => import("./magnolia/templates/EventsPage"));
const OpenJobsPage = dynamic(() => import("./magnolia/templates/OpenJobsPage"));
const JobsPage = dynamic(() => import("./magnolia/templates/JobsPage"));
const EventsCalendarPage = dynamic(() => import("./magnolia/templates/EventsCalendar"));
const ArticlesFiltersPage = dynamic(
  () => import("./magnolia/templates/ArticlesFiltersPage")
);

// Components
const ArticleChart = dynamic(
  () => import("./magnolia/components/ArticleChart")
);
const ArticleFooterSection = dynamic(
  () => import("./magnolia/components/ArticleFooterSection")
);
const ArticleImageGallery = dynamic(
  () => import("./magnolia/components/ArticleImageGallery")
);
const ArticlePageHero = dynamic(
  () => import("./magnolia/components/ArticlePageHero")
);
const ArticleQuote = dynamic(
  () => import("./magnolia/components/ArticleQuote")
);
const ArticleSection = dynamic(
  () => import("./magnolia/components/ArticleSection")
);
const CompanyList = dynamic(() => import("./magnolia/components/CompanyList"));
const CompanyForm = dynamic(() => import("./magnolia/components/CompanyForm"));
const ContactCard = dynamic(() => import("./magnolia/components/ContactCard"));
const CTABanner = dynamic(() => import("./magnolia/components/CTABanner"));
const Hero = dynamic(() => import("./magnolia/components/Hero"));
const LinkPreview = dynamic(() => import("./magnolia/components/LinkPreview"));
const MapBox = dynamic(() => import("./magnolia/components/MapBox"));
const Masonry = dynamic(() => import("./magnolia/components/Masonry"));
const MigriWidget = dynamic(() => import("./magnolia/components/MigriWidget"));
const OpenJobsHighlight = dynamic(
  () => import("./magnolia/components/OpenJobsHighlight")
);
const PageHeader = dynamic(() => import("./magnolia/components/PageHeader"));
const PromotionBanner = dynamic(
  () => import("./magnolia/components/PromotionBanner")
);
const Quotes = dynamic(() => import("./magnolia/components/Quotes"));
const Recommendations = dynamic(
  () => import("./magnolia/components/Recommendations")
);
const SectionHeader = dynamic(
  () => import("./magnolia/components/SectionHeader")
);
const Tabs = dynamic(() => import("./magnolia/components/Tabs"));
const Video = dynamic(() => import("./magnolia/components/Video"));
const Quiz = dynamic(() => import("./magnolia/components/Quiz"));
const Form = dynamic(
  () => import("./magnolia/components/Form")
);
const FormGroupFields = dynamic(
  () => import("./magnolia/components/Form/components/FormGroupFields")
);
const FormEdit = dynamic(
  () => import("./magnolia/components/Form/components/FormEdit")
);
const FormSelection = dynamic(
  () => import("./magnolia/components/Form/components/FormSelection")
);
const FormFile = dynamic(
  () => import("./magnolia/components/Form/components/FormFile")
);
const FormSubmit = dynamic(
  () => import("./magnolia/components/Form/components/FormSubmit")
);

export enum MagnoliaTemplate {
  // Pages
  Page = "wif-lm:pages/page",
  PageArticle = "wif-lm:pages/article",
  PageArticleFilter = "wif-lm:pages/articleFilter",
  PageDeleted = "pages-app:deleted",
  PageEmployer = "wif-lm:pages/employer",
  PageEvent = "wif-lm:pages/event",
  PageEventCalendar = "wif-lm:pages/eventCalendar",
  PageOpenJobs = "wif-lm:pages/openJobsPage",
  PageJobs = "wif-lm:pages/jobsPage",
  PageRedirect = "wif-lm:pages/redirect",

  // Components
  ArticleChart = "wif-lm:components/articleChart",
  ArticleFooterSection = "wif-lm:components/footerSection",
  ArticleImageGallery = "wif-lm:components/articleImageGallery",
  ArticlePageHero = "wif-lm:components/articlePageHero",
  ArticleQuote = "wif-lm:components/articleQuote",
  ArticleSection = "wif-lm:components/articleSection",
  CompanyList = "wif-lm:components/companyList",
  CompanyForm = "wif-lm:components/companyForm",
  ContactCard = "wif-lm:components/contactCard",
  CTABanner = "wif-lm:components/ctaBanner",
  Hero = "wif-lm:components/hero",
  LinkPreview = "wif-lm:components/linkPreview",
  Map = "wif-lm:components/mapBox",
  Masonry = "wif-lm:components/masonry",
  MigriWidget = "wif-lm:components/migriWidget",
  OpenJobsHighlight = "wif-lm:components/openJobsHighlight",
  PageHeader = "wif-lm:components/pageHeader",
  PromotionBanner = "wif-lm:components/promotionBanner",
  Quotes = "wif-lm:components/quotes",
  Recommendations = "wif-lm:components/recommendations",
  SectionHeader = "wif-lm:components/sectionHeader",
  Tabs = "wif-lm:components/tabs",
  Video = "wif-lm:components/articleVideo",
  Quiz = "wif-lm:components/quiz",
  Form = "form:components/form",
  FormGroupFields = "form:components/formGroupFields",
  FormEdit = "form:components/formEdit",
  FormNumber = "form:components/formNumber",
  FormSubmit = "form:components/formSubmit",
  FormSelection = "form:components/formSelection",
  FormFile = "form:components/formFile",
}

// Note: Add to the type-list, the MGNL templates
//       which do NOT have an associated component.
type MagnoliaTemplateWithoutComponent =
  | MagnoliaTemplate.PageRedirect
  | MagnoliaTemplate.PageDeleted;

type MagnoliaTemplateWithComponent = Exclude<
  MagnoliaTemplate,
  MagnoliaTemplateWithoutComponent
>;

export const magnoliaEditablePageConfig: Readonly<{
  componentMappings: Record<
    MagnoliaTemplateWithComponent,
    React.ComponentType<any>
  >;
}> = {
  componentMappings: {
    // Pages
    [MagnoliaTemplate.Page]: Page,
    [MagnoliaTemplate.PageArticle]: Page,
    [MagnoliaTemplate.PageArticleFilter]: ArticlesFiltersPage,
    [MagnoliaTemplate.PageEmployer]: Page,
    [MagnoliaTemplate.PageEvent]: EventsPage,
    [MagnoliaTemplate.PageEventCalendar]: EventsCalendarPage,
    [MagnoliaTemplate.PageOpenJobs]: OpenJobsPage,
    [MagnoliaTemplate.PageJobs]: JobsPage,

    // Components
    [MagnoliaTemplate.ArticleChart]: ArticleChart,
    [MagnoliaTemplate.ArticleFooterSection]: ArticleFooterSection,
    [MagnoliaTemplate.ArticleImageGallery]: ArticleImageGallery,
    [MagnoliaTemplate.ArticlePageHero]: ArticlePageHero,
    [MagnoliaTemplate.ArticleQuote]: ArticleQuote,
    [MagnoliaTemplate.ArticleSection]: ArticleSection,
    [MagnoliaTemplate.CompanyList]: CompanyList,
    [MagnoliaTemplate.CompanyForm]: CompanyForm,
    [MagnoliaTemplate.ContactCard]: ContactCard,
    [MagnoliaTemplate.CTABanner]: CTABanner,
    [MagnoliaTemplate.LinkPreview]: LinkPreview,
    [MagnoliaTemplate.Hero]: Hero,
    [MagnoliaTemplate.Map]: MapBox,
    [MagnoliaTemplate.Masonry]: Masonry,
    [MagnoliaTemplate.MigriWidget]: MigriWidget,
    [MagnoliaTemplate.OpenJobsHighlight]: OpenJobsHighlight,
    [MagnoliaTemplate.PageHeader]: PageHeader,
    [MagnoliaTemplate.PromotionBanner]: PromotionBanner,
    [MagnoliaTemplate.Quotes]: Quotes,
    [MagnoliaTemplate.Recommendations]: Recommendations,
    [MagnoliaTemplate.SectionHeader]: SectionHeader,
    [MagnoliaTemplate.Tabs]: Tabs,
    [MagnoliaTemplate.Video]: Video,
    [MagnoliaTemplate.Quiz]: Quiz,
    [MagnoliaTemplate.Form]: Form,
    [MagnoliaTemplate.FormGroupFields]: FormGroupFields,
    [MagnoliaTemplate.FormEdit]: FormEdit,
    [MagnoliaTemplate.FormNumber]: FormEdit, // tmp
    [MagnoliaTemplate.FormSelection]: FormSelection,
    [MagnoliaTemplate.FormFile]: FormFile,
    [MagnoliaTemplate.FormSubmit]: FormSubmit,
  },
} as const;

/**
 * The helper function finds and returns the Component associated
 * with the provided MGNL template from the componentMappings.
 * If component is not found, `undefined` is returned.
 */
export function getComponentForMagnoliaTemplate(
  template: string | undefined
): React.ComponentType<any> | undefined {
  if (!template) {
    return undefined;
  }

  const validTemplate = Object.keys(
    magnoliaEditablePageConfig.componentMappings
  ).find((t) => t === template) as MagnoliaTemplateWithComponent | undefined;

  if (!validTemplate) {
    return undefined;
  }

  return magnoliaEditablePageConfig.componentMappings[validTemplate];
}
