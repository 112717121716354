/* eslint-disable max-len */

export class MGNL {
  /**
   * URL for the current public deployment of Magnolia instance.
   * @example "http://localhost:8080"
   * @required
   */
  static get HOST_PUBLIC(): string {
    const host = String(process.env.NEXT_PUBLIC_MGNL_HOST || "");

    if (!host) {
      throw new Error("Env var NEXT_PUBLIC_MGNL_HOST is required.");
    }

    return host;
  }

  /**
   * URL for the current author deployment of Magnolia instance.
   * @example "http://localhost:8080"
   */
  static get HOST_AUTHOR(): string {
    return String(process.env.NEXT_PUBLIC_MGNL_HOST_AUTHOR || this.HOST_PUBLIC);
  }

  /**
   * URL for the current author or public deployment of Magnolia instance.
   * If `isPreview` is set to `true`, path for the author instance will be used,
   * else path for the public instance will be used.
   * @example "http://localhost:8080"
   */
  static HOST(isPreview = false): string {
    return isPreview ? this.HOST_AUTHOR : this.HOST_PUBLIC;
  }

  /**
   * Subpath of Magnolia's author instance. Could be empty string.
   * @example "/magnoliaAuthor"
   * @default ""
   */
  static get AUTHOR_PATH(): string {
    return String(process.env.NEXT_PUBLIC_MGNL_AUTHOR || "");
  }

  /**
   * Magnolia root node (parent page-node for all pages/content).
   * @default "/wif"
   */
  static get ROOT_NODE(): string {
    return String(process.env.NEXT_PUBLIC_MGNL_ROOT || "/wif");
  }

  /**
   * Magnolia root node for employers site .
   * @default "/wif/tyonantajat"
   */
  static get EMPLOYERS_NODE(): string {
    return String(this.ROOT_NODE + "/tyonantajat");
  }

  /**
   * Used as base for all API endpoints
   * @example "http://localhost:8080/magnoliaAuthor"
   */
  static BASE_URL(isPreview = false): string {
    return this.HOST(isPreview) + this.AUTHOR_PATH;
  }

  /**
   * Used as base for all API endpoints
   * @example "http://localhost:8080/magnoliaAuthor"
   */
  static DELIVERY_API(isPreview = false): string {
    return this.BASE_URL(isPreview) + "/.rest/wif";
  }

  static BF_CUSTOM_API(isPreview = false): string {
    return this.BASE_URL(isPreview) + "/.rest/bf";
  }

  static get AUTH_TOKEN(): string | undefined {
    return process.env.MGNL_AUTH_TOKEN;
  }
}

export class MGNL_API {
  /**
   * Get all companies.
   */
  static COMPANIES(isPreview = false): string {
    return MGNL.DELIVERY_API(isPreview) + "/companies";
  }

  /**
   * Get all content for the current page node to render page.
   */
  static PAGE_CONTENT(isPreview = false): string {
    return MGNL.DELIVERY_API(isPreview) + "/page-content";
  }

  /**
   * Get minimum data for all page-nodes that are required to build navigation.
   */
  static NAVIGATION(isPreview = false): string {
    return MGNL.DELIVERY_API(isPreview) + "/navigation";
  }

  /**
   * Get minimum data for a list of page-nodes. Limit=100.
   */
  static PAGES_100(isPreview = false): string {
    return MGNL.DELIVERY_API(isPreview) + "/pages/100";
  }

  /**
   * Get minimum data for a list of page-nodes. Limit=10.
   */
  static PAGES_10(isPreview = false): string {
    return MGNL.DELIVERY_API(isPreview) + "/pages/10";
  }

  /**
   * Get minimum data for all page-nodes under ROOT_NODE
   * to statically generate pages during build-time (all languages).
   */
  static PATHS(isPreview = false): string {
    return MGNL.DELIVERY_API(isPreview) + "/paths";
  }

  /**
   * Get minimum data for all page-nodes under ROOT_NODE
   * to statically generate pages during build-time (all languages).
   */
  static PATHS_VIDEOS(isPreview = false): string {
    return MGNL.DELIVERY_API(isPreview) + "/paths/videos";
  }

  /**
   * Get minimum data for all page-nodes under ROOT_NODE
   * to statically generate pages during build-time (all languages).
   */
  static PATHS_IMAGES(isPreview = false): string {
    return MGNL.DELIVERY_API(isPreview) + "/paths/images";
  }

  /**
   * Get all services.
   */
  static SERVICES(isPreview = false): string {
    return MGNL.DELIVERY_API(isPreview) + "/services";
  }

  /**
   * Form module custom endpoint.
   */
  static FORM_SUBMIT(isPreview = false): string {
    return MGNL.BF_CUSTOM_API(isPreview) + "/form/submit";
  }

  /**
   * Get template annotations for current page during preview,
   * so the Magnolia editor can render toolbars.
   */
  static TEMPLATE(): string {
    return MGNL.BASE_URL(true) + "/.rest/template-annotations/v1";
  }

  /**
   * Magnolia Nodes API endpoint.
   * @see https://docs.magnolia-cms.com/product-docs/6.2/Developing/API/Nodes-and-properties-APIs/Nodes-API.html
   */
  static NODES(
    workspace: string,
    path = "/",
    options: {
      isPreview?: boolean;
      depth?: number;
      excludeNodeTypes?: string[];
      includeMetadata?: boolean;
    } = {}
  ): string {
    const {
      isPreview = false,
      depth = 0,
      excludeNodeTypes,
      includeMetadata,
    } = options;
    const url =
      MGNL.BASE_URL(isPreview) + `/.rest/nodes/v1/${workspace}${path}`;

    if (!depth && !excludeNodeTypes && !includeMetadata) {
      return url;
    }

    const searchParams = new URLSearchParams();
    searchParams.set("depth", depth.toString());
    searchParams.set("excludeNodeTypes", excludeNodeTypes?.join(",") || "");
    searchParams.set("includeMetadata", String(Boolean(includeMetadata)));

    return `${url}?${searchParams.toString()}`;
  }
}
